import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/website/Home.vue"),
  },
  {
    path: "/registration/visitor/step1",
    name: "visitor1",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/visitor/Visitor1.vue"),
  },
  {
    path: "/registration/visitor/step2",
    name: "visitor2",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/visitor/Visitor2.vue"),
  },
  {
    path: "/registration/vip-guest/step1",
    name: "guest1",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/guest/Guest1.vue"),
  },
  {
    path: "/registration/vip-guest/step2",
    name: "guest2",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/guest/Guest2.vue"),
  },
  {
    path: "/registration/guest-companion/step1",
    name: "guestCompanion1",
    meta: {
      layout: "WebLayout",
    },
    component: () =>
      import("../views/registration/guest/companion/Companion1.vue"),
  },
  {
    path: "/registration/guest-companion/step2",
    name: "guestCompanion2",
    meta: {
      layout: "WebLayout",
    },
    component: () =>
      import("../views/registration/guest/companion/Companion2.vue"),
  },
  {
    path: "/registration/vip-speaker/step1",
    name: "Speaker1",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/speaker/Speaker1.vue"),
  },
  {
    path: "/registration/vip-speaker/step2",
    name: "Speaker2",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/speaker/Speaker2.vue"),
  },
  {
    path: "/registration/exhibitor/step1",
    name: "Exhibitor1",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/exhibitor/Exhibitor1.vue"),
  },
  {
    path: "/registration/exhibitor/step2",
    name: "Exhibitor2",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/exhibitor/Exhibitor2.vue"),
  },
  {
    path: "/registration/exhibitor/staff/step1",
    name: "ExhibitorStaff1",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/exhibitor/staff/Staff1.vue"),
  },
  {
    path: "/registration/exhibitor/staff/step2",
    name: "ExhibitorStaff2",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/exhibitor/staff/Staff2.vue"),
  },

  {
    path: "/registration/media/step1",
    name: "Media1",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/media/Media1.vue"),
  },
  {
    path: "/registration/media/step2",
    name: "Media2",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/media/Media2.vue"),
  },
  {
    path: "/registration/media/staff/step1",
    name: "MediaStaff1",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/media/staff/Staff1.vue"),
  },
  {
    path: "/registration/media/staff/step2",
    name: "MediaStaff2",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/registration/media/staff/Staff2.vue"),
  },
  {
    path: '/side-event',
    name: 'SideEvent',
    meta: { 
      layout: 'WebLayout' } ,
    component: () => import('../views/sideEvent/sideEvent.vue')
  },
  {
    path: '/room-booking/step1',
    name: 'RoomBookingStep1',
    // meta: { 
    //   layout: 'WebLayout' } ,
    component: () => import('../views/roomBooking/roomBookingStep1.vue')
  },
  {
    path: '/room-booking/step2',
    name: 'RoomBookingStep2',
    // meta: { 
    //   layout: 'WebLayout' } ,
    component: () => import('../views/roomBooking/roomBooking.vue')
  },
  
  {
    path: '/media-center',
    name: 'MediaCenter',
    meta: { 
      layout: 'WebLayout' } ,
    component: () => import('../views/website/MediaCenter.vue')
  },


  {
    path: '/registration/visa',
    name: 'Visa',
    // meta: { 
    //   layout: 'WebLayout' } ,
    component: () => import('../views/registration/visa/Visa.vue')
  },
  {
    path: "/registration/visa",
    name: "Visa",
    // meta: {
    //   layout: "WebLayout",
    // },
    component: () => import("../views/registration/visa/Visa.vue"),
  },
  {
    path: "/visit-saudi",
    name: "VisitSaudi",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/website/VisitSaudi.vue"),
  },
  
  {
    path: "/ebadge",
    name: "EBadge",
    // meta: {
    //   layout: "WebLayout",
    // },
    component: () => import("../views/registration/badge/Badge.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      requiresNoAuth: true,
    },
    component: () => import("../views/registration/badge/Contact.vue"),
  },
  {
    path: "/checkin-login",
    name: "CheckinLoginView",
    meta: {
      requiresNoAuth: true,
    },
    component: function () {
      return import("../views/CheckinLoginView.vue");
    },
  },
  {
    path: "/check",
    name: "CheckView",
    meta: {
      requiresAuth: true,
    },
    component: function () {
      return import("../views/CheckView.vue");
    },
  },
  {
    path: "/room-login",
    name: "RoomLoginView",
    meta: {
      roomRequiresNoAuth: true,
    },
    component: function () {
      return import("../views/room-booking/RoomLoginView.vue");
    },
  },
  {
    path: "/room-bookings",
    name: "RoomBookingsView",
    meta: {
      roomRequiresAuth: true,
    },
    component: function () {
      return import("../views/room-booking/RoomBookingsView.vue");
    },
  },
  {
    path: "/bookings-by-room",
    name: "BookingsByRoomView",
    component: function () {
      return import("../views/room-booking/BookingsByRoomView.vue");
    },
  },
  {
    path: "/check-booking",
    name: "CheckBookingView",
    meta: {
      roomRequiresAuth: true,
    },
    component: function () {
      return import("../views/room-booking/CheckBookingView.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// CHECKIN
router.beforeEach((to, from, next) => {
  const userAuthenticated = localStorage.getItem("auth_token") !== null;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresNoAuth = to.matched.some(
    (record) => record.meta.requiresNoAuth
  );
  if (requiresAuth && !userAuthenticated) {
    next("/checkin-login");
  } else if (requiresNoAuth && userAuthenticated) {
    next("/check");
  } else {
    next();
  }
});

// ROOM BOOKING
router.beforeEach((to, from, next) => {
  const roomUserAuthenticated =
    localStorage.getItem("room_auth_token") !== null;
  const roomRequiresAuth = to.matched.some(
    (record) => record.meta.roomRequiresAuth
  );
  const roomRequiresNoAuth = to.matched.some(
    (record) => record.meta.roomRequiresNoAuth
  );
  if (roomRequiresAuth && !roomUserAuthenticated) {
    next("/room-login");
  } else if (roomRequiresNoAuth && roomUserAuthenticated) {
    next("/room-bookings");
  } else {
    next();
  }
});

export default router;
