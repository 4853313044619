
<template class="">
  <router-view></router-view>
  <div v-if="$store.state.loader==1" class="pf ov bgb60">
    <div class="cc">
      <span class="loader"></span> 
    </div>
  </div>
  <div v-if="$store.state.showMsgDiv==1" class="pf ov bgb40 overlayDiv" style="z-index:99999999999">
    <div class="cc bgb brdrGold sh20 round2x ph20 pv50 w50 m_w75 m_m_w90 msg_div" style="max-width:350px"> 
      <div v-if="$store.state.apiSuccess==true">
        <p class="success mt20 fs20 enter rregular cLightGold" style="color:#C7A879">{{$store.state.success_msg}}</p>
        <div class="mt20 c">
          <button class="sbtn fs20 success" style="background-color:#C7A879;padding: 8px 40px; border-color:#C7A879 ;" @click="closeModal(1)">{{   this.$t('ok') }}</button>
        </div>
      </div>
      <div v-if="$store.state.apiSuccess==false">
        <p class="error error_msg cLightGold mt20 fs30 enter rregular">{{$store.state.error_msg}}</p>
        <div class="mt20 c">
          <button class="sbtn fs20 success" style="background-color:#C7A879;padding: 8px 40px; border-color:#C7A879 ;" @click="closeModal(0)">{{   this.$t('ok') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "EmptyLayout",
    methods:{
      closeModal(val){
        if(val==1){
          this.$router.push({name:"home"});
          this.$store.state.showMsgDiv=0;
        }
        else{
          this.$store.state.showMsgDiv=0;
        }
      },
    }
  }
</script>