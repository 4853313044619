import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import Select2 from "vue3-select2-component";
import axios from "axios";
import VueAxios from "vue-axios";
import VueQRCodeComponent from "vue-qrcode-component";
import "./assets/css/util.css";
import "maz-ui/css/main.css";
import "./assets/css/mdi.css";
import "./assets/css/bea.css";
import "./assets/css/custom.css";

import VueCookies from 'vue3-cookies'


const app = createApp(App).use(store).use(router);
app.use(i18n);
app.component("Select2", Select2);
app.use(VueAxios, axios);
app.component("qr-code", VueQRCodeComponent);
app.use(VueCookies)

app.mount("#app");
