import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      register_now:"Register now",
      about_us:"About Us",
      speakers:"Speakers",
      panel_discussion:"Panel Discussions",
      agenda:"Agenda",
      exhibitors:"Exhibitors",
      faq:"FAQ",
      visit_saudi:"Visit Saudi",
      witness_transsformation:"Witness Transformation",
      momentoum:"momentoum",
      development_finance_confrence:"development finance confrence",
      date_and_location:"14 - 15 february 2024, Riyadh, KSA" ,
      conference_agenda:"Conference Agenda",
      day_1:"Day 1",
      day_2:"Day 2",
      contact_us:"Contact Us",
      welcome_to_momentum:"WELCOME TO MOMENTUM!",
      title:"Title",
      title_is_required:"Title is required",
      gender:"Gender",
      gender_is_required:"Gender is required",
      first_name_is_required:"First Name is required",
      last_name_is_required:"Last Name is required",
      phone_is_required:"Mobile number is required",
      first_name:"First Name",
      last_name:"Last Name",
      phone:"Mobile Number",
      email_is_required:"Email is required",
      confirm_email_is_required:"Confirm Email is required",
      filed_is_required:"Field is required",
      email_does_not_match:"Emails don't match",
      alternative_email_is_required:"Alternative Email is required",
      email:"Email",
      confirm_email:"Confirm Email",
      alternative_email:"Alternative Email",
      nationality:"Nationality",
      nationality_is_required:"Nationality is required",
      country_of_residence:"Country of Residence",
      country_of_residence_is_required:"Country of residence is required",
      identification_number:"Identification Number",
      identification_number_is_reuired:"Identification Number is required",
      passportNumber:"Passport Number",
      email_is_invalid:"Please check email",
      country_code:"Country Code",
      phone_already_taken:"The mobile number has already been used",
      email_already_taken:"The email has already been used",
      legal_number_already_taken:"The legal number has already been used",
      region:'Region',
      region_is_required:'Region is required',
      city:"City",
      city_is_required:'City is required',
      industry:"industry",
      industry_is_required:'Industry is required',
      company:"company",
      company_is_required:'Company is required',
      position:"position",
      position_is_required:'Position is required',
      interest:"interest in the conference",
      specify_interest_in_conference:"Specify ohter interest",
      interest_is_required:'Interest is required',
      upload_personal_photo:"upload personal photo",
      personal_photo_is_required:'Personal photo is required',
      upload_passport_photo:"upload Passport Photo",
      upload_id_photo:"upload Identification Photo",
      upload_iqama_photo:"upload Iqama Photo",
      passport_photo:"Passport Photo",
      id_photo:"Id Photo",
      iqama_photo:"Iqama Photo",
      legal_document_is_required:"Legal document is required",
      upload_photo:"upload personal photo",
      interest_in_conference:"eg: Interest in conference",
      file_is_too_large:"File is too large",
      submit:"Submit",
      back:"Back",
      ok:"Ok",
      registration_successfully:"Thanks for your registration. \n You will receive an email soon.",
      operationFailed:"Submitting form error! \n please try again later",
      registration_form:"Registration Form",
      step1:"Step 1",
      step2:"Step 2",
      next:"Next",
      industry_other:"Industry Other",
      invalid_id:"Please enter a correct ID number",
      iqama_correct_number:"Please enter a correct Iqama number",
      passport_correct_number:"Please enter a correct passport number",
      enter_only_number:"You should enter numbers only",
      phoneNumber:"Mobile Number",
      idNumber:"Identification Number",
      iqamaNumber:"Iqama Number",
      phone_not_valid:"Please enter a valid mobile number",
      next_step:"Next Step",
      do_you_need_visa:"Do you need visa?",
      request_visa:"Request visa",
      yes:"yes",
      no:"no",
      academic_qualification:"Academic Qualification",
      academic_qualification_is_required:"Academic qualification is required",
      job_title:"Job Title",
      job_title_is_required:"Job title is required",
      place_of_job:"Work place address:",
      place_of_job_is_required:"Work place address is required",
      date_of_birth:"Date of Birth",
      date_of_birth_is_required:"Date of birth is required",
      max_date_of_birth:"Maximum date of birth is: 01-01-2005 ",
      place_of_birth:"Place of Birth",
      place_of_birth_is_required:"Place of birth is required",
      passport_type:"Passport Type",
      passport_type_is_required:"Passport type is required",
      passport_expiry_date:"Passport Expiry Date",
      passport_expiry_date_is_required:"Passport expiry date is required",
      min_passport_expiry_date:"Minmum passport expiry date is: 01-05-2023",
      facebook:"Facebook",
      facebook_is_required:"Facebook is required",
      twitter:"twitter",
      twitter_is_required:"Twitter is required",
      full_name:"Full Name",
      full_name_is_required:"Full name is required",
      invited_by:"Invited By",
      invited_by_is_required:"Invited by is required",
      date_of_issuance:"Date of Issuance",
      date_of_issuance_is_required:"Date of Issuance is required",
      place_of_issuance_of_the_passport:"Place of Issuance of the Passport",
      place_of_issuance_of_the_passport_is_required:"Place of issuance of the passport is required",
      number_of_entries:"Number of Entries",
      stay_days:"The duration of stay in days",
      religion:"Religion",
      religion_is_required:"Religion is required",
      upload_logo:"Upload Logo",
      supported_files:"Supported files",
      compnay_name:"Compnay Name",
      logo_is_required:"Logo is required",
      personal_info_hint:"For faster updates, could you please share your personal email",
      days:"Days",
      about_riyadh:"About Riyadh",
      wlc_riyadh:"Welcome to Riyadh, capital of the Kingdom of Saudi Arabia.",
      about_riyadh_desc_p1:"Riyadh is the starting point and cradle of Saudi history, the beating heart of the Middle East with growth and prosperity. It carries within it a long history that boasts its landmarks.",
      about_riyadh_desc_p2:"The third largest Arab capital in terms of population, with approximately 7 million people, it includes the headquarters of the sovereign institutions, ministries, and government and military bodies. It is considered a financial center that includes King Abdullah Financial District (KAFD), the Capital Market Authority, the Saudi Financial Market (Saudi Exchange), and the headquarters of banks and investment companies and KAFD.",
      VRPT:"Notable Projects",
      actT:"Attractions & Activities",
      act1:"Sky Bridge at Kingdom Center",
      act2:"Al Murabaa Palace",
      act3:"Wadi Namar",
      act4:"Edge of the World",
      act5:"The Saudi National Museum",
      act6:"Al Faisaliah Tower",
      act7:"Al Masmak Palace",
      act8:"Boulevard Riyadh City",
      act9:"King Fahad National Library",
      act10:"Roshn Front",
      visa_successfully:"Thanks for submiting visa from. \n  We will contact you soon.",
      already_has_visa:"Sorry, You have already registered your visa information",
      incorrect_link:"Sorry, this link is incorrect",
      company_name:"Company Name",
      of:"of",
      staff:"Staff",
      media_center:"Media Center",
      about_p:"Discover the meeting point between the three major continents, a region blessed with environmentally friendly, social, economic, and cultural elements that are not found anywhere else. \n Its area is close to the size of a continent 2.15 Millon Km2, and it overlooks wide beaches on both sides. Oriental and education. \n In its central region, its capital is Riyadh, which is the third Arab capital in terms of population. It contains the headquarters of sovereign institutions and is a global financial center with a large number of different financial centers and bodies settled there. \n In its west, Mecca and Medina that illuminate the depth of the kingdom in the Islamic conscience. \n Aramco is located in its eastern region. It shows the face of the country that has the second largest alternative to petroleum, the largest exporter of oil in the world, and the sixth alternative to gas. \n In Al Ula, you can explore the Kingdom’s diverse cultural and civilizational heritage dating back thousands of years. Saudia Arabia is a founding member of the United Nations Educational and Scientific Organization (UNESCO), and a member of its Executive Council.",
      learn_more_about_ksa:"Learn more about Saudi",
      learn_more:"Learn More",
      address:"Residential address",
      address_is_required:"Address is required",
      registerd_before:"You have already been registered",
      visa_form:"Visa Form",
      at_least_one_staff:"You have to add at least one staff",
      add_staff:"Add staff",
      duplicated_emails:"Emails already been used",
      duplicated_phones:"Phone numbers already been used",
      example:"Example:",
      missing_fields:"Please complete all the required fields before submitting",
      personal_photo_hint:"Please submit a 2x3 inch high resolution personal photo",
      media_name:"Media Name",
      reg_staff_before:"Your records have already been submitted",
      hd_logo:"Please upload the organization logo with high resolution",
      invited_by_hint:'Please mention the name of the company that invited you. \n eg:"National Development Fund"',
      hosted_by:"Hosetd By",
      media_kit:"Media Kit",
      download:"Download",
      see_more:"See More",
      announcement:"Announcements",
      videos:"Videos",
      images:"Images",
      official_gallery:"Official Gallery",
      view_more:"View More",
      social_media_guidance:"Social Media Guidance",
      share:'share',
      live_streaming:"live streaming",
      arabic:"Arabic",
      english:"English",
      branding:"branding",
      valid_link:"Please enter a valid link",
      session_expire:"Session expiration \n kindly update your information.",
      side_event_question:"Would you consider submitting your interest to attend exciting events?",
      available_dates:"Available Dates",
      available_times:"Available Times",
      select_time:"No time selected, \n Please select time before submit",
      choose_room:"Choose Room",
      choose_time:"Choose Time",
      date:"Date",
      from:"From",
      to:"To",
      social_media:"Follow Us",
      Conference_Location:"Conference Location",
      already_have_side_event:"Sorry, You have already registered for side event before",
      numnber_of_attendes:"Number of attendees",
      name_of_attende:"Name of attende",
      max_attendees:"Max number of attendees is 10",
      scan_badge_1:"Kindly scan the QR code to book a room.",
      scan_badge_2:"Please be ready before the meeting, at least 10 to 5 minutes in advance, or you may lose your meeting room.",
      room_success_pop:"Note: \n Please be ready before the meeting, at least 10 to 5 minutes in advance, or you may lose your meeting room.",
      taken_time:"Time has already been booked",
      no_room_available:"Sorry there is no available room",
      please_select_date_time:"Please select date and time",
      some_selected_event:"Please select date and time for all selected events",
      already_selected_date:"This date is selected in another event, unseleect it in the other event so you can select it here",
      personal_image_validation:"The Image should be less than 6MB.\n The image should be more than 100px in width and height",
      topic:"Topic",





      
    },
    'ar': {
      register_now:"سجل الآن",
      about_us:"عن المؤتمر",
      speakers:"المتحدثون",
      panel_discussion:"جلسات حوارية",
      agenda:"البرنامج",
      exhibitors:"العارضون",
      faq:"الأسئلة الشائعة",
      visit_saudi:"روح السعودية",
      witness_transsformation:"Witness Transsformation",
      momentoum:"momentoum",
      date_and_location:"14 - 15 فبراير 2024, الرياض, المملكة العربية السعودية" ,
      conference_agenda:"برنامج المؤتمر",
      day_1:"اليوم الأول",
      day_2:"اليوم الثاني",
      contact_us:"تواصل معنا",
      welcome_to_momentum:"أهلاً بكم في MOMENTUM!",
      title:"اللقب",
      title_is_required:"الرجاء إدخال اللقب",
      gender:"الجنس",
      gender_is_required:"الرجاء إدخال الجنس",
      first_name_is_required:"الرجاء إدخال الاسم الأول",
      last_name_is_required:"الرجاء إدخال الاسم الاخير",
      phone_is_required:"الرجاء إدخال رقم الهاتف",
      first_name:"الاسم الأول",
      last_name:"الاسم الاخير",
      phone:"رقم الجوال",
      email_is_required:"الرجاء إدخال البريد الإلكتروني",
      confirm_email_is_required:"الرجاء تأكيد البريد الإلكتروني",
      filed_is_required:"هذا الحقل إجباري",
      email_does_not_match:"البريد الإلكتروني غير متطابق",
      alternative_email_is_required:"الرجاء إدخال البريد الإلكتروني الشخصي",
      email:"البريد الإلكتروني",
      confirm_email:"تأكيد البريد الإلكتروني",
      alternative_email:"البريد الإلكتروني الشخصي",
      nationality:"الجنسية",
      nationality_is_required:"الرجاء إختيار الجنسية",
      country_of_residence:"بلد الإقامة",
      country_of_residence_is_required:"الرجاء إدخال بلد الإقامة",
      passportNumber:"رقم جواز السفر",
      email_is_invalid:"الرجاء التأكد من البريد الإلكتروني",
      country_code:"رمز المنطقة",
      phone_already_taken:"رقم الهاتف مسجل مسبقاً",
      email_already_taken:"البريد الإلكتروني مسجل مسبقاً",
      legal_number_already_taken:"رقم الوثيقة مسجل مسبقاً",
      region:'المنطقة',
      region_is_required:'االرجاء إدخال المنطقة',
      city:"المدينة",
      city_is_required:'االرجاء إدخال المدينة',
      industry:"المجال",
      industry_is_required:'االرجاء إدخال المجال',
      company:"الشركة",
      company_is_required:'االرجاء إدخال الشركة',
      position:"المنصب",
      position_is_required:'االرجاء إدخال المنصب',
      interest:"اهتمام في المؤتمر",
      specify_interest_in_conference:"حدد الإهتمامات الأخرى",
      interest_is_required:'االرجاء إدخال الاهتمامات',
      upload_personal_photo:"تحميل الصورة الشخصية",
      personal_photo_is_required:'الرجاء  تحميل الصورة الشخصية',
      upload_passport_photo:"تحميل صورة جواز السفر",
      upload_id_photo:"تحميل صورة  البطاقة الشخصية",
      upload_iqama_photo:"تحميل صورة الاقامة",
      passport_photo:"صورة جواز السفر",
      id_photo:"صورة  البطاقة الشخصية",
      iqama_photo:"صورة الاقامة",
      legal_document_is_required:"الرجاء تحميل الصورة",
      upload_photo:"تحميل الصورة الشخصية",
      interest_in_conference:"مثلا: اهتمامات...",
      file_is_too_large:"الملف أكبر من الحجم المسموح",
      submit:"تسجيل",
      back:"رجوع",
      ok:"حسناً",
      registration_successfully:"شكرًا لتسجيلك. \n سيصلك بريد إلكتروني قريبًا",
      operationFailed:"خطأ في تقديم النموذج!\n  يرجى المحاولة مرة أخرى لاحقًا.",
      registration_form:"نموذج التسجيل",
      step1:"الخطوة ١",
      step2:"الخطوة ٢",
      next:"التالي",
      industry_other:"القطاع",
      invalid_id:"الرجاء إدخال رقم الهوية الصحيح",
      iqama_correct_number:"الرجاء ادخال رقم الإقامة الصحيح",
      passport_correct_number:"الرجاء إدخال رقم جواز السفر الصحيح",
      enter_only_number:"يجب أن تدخل أرقاماً فقط",
      phoneNumber:"رقم الجوال",
      idNumber:"رقم الهوية",
      iqamaNumber:"رقم الإقامة",
      phone_not_valid:"الرجاء التأكد من رقم الهاتف ",
      next_step:"التالي",
      do_you_need_visa:"هل تحتاج إلى تأشيرة؟",
      request_visa:"طلب تأشيرة",
      yes:"نعم",
      no:"كلا",
      academic_qualification:"المؤهلات العلمية",
      academic_qualification_is_required:"الرجاء إدخال المؤهلات العلمية",
      job_title:"المسمى الوظيفي",
      job_title_is_required:"الرجاء إدخال المسمى الوظيفي",
      place_of_job:"مكان العمل",
      place_of_job_is_required:"الرجاء إدخال مكان العمل",
      date_of_birth:"تاريخ الولادة",
      date_of_birth_is_required:"الرجاء إدخال تاريخ الولادة",
      max_date_of_birth:"الحد الأقصى لتاريخ الولادة هو: 01-01-2005",
      place_of_birth:"مكان الولادة",
      place_of_birth_is_required:"الرجاء إدخال مكان الولادة",
      passport_type:"نوع جواز السفر",
      passport_type_is_required:"الرجاء إدخال نوع جواز السفر",
      passport_expiry_date:"تاريخ إنتهاء جواز السفر",
      passport_expiry_date_is_required:"الرجاء إدخال تاريخ جواز السفر",
      min_passport_expiry_date:"الحد الأدنى لتاريخ إنتهاء جواز السفر هو:01-05-2023",
      facebook:"فيسبوك",
      twitter:"تويتر",
      full_name:"الاسم الكامل",
      full_name_is_required:"الرجاء إدخال الاسم الكامل",
      invited_by:"مدعو من قبل",
      invited_by_is_required:"الرجاء إدخال لال",
      date_of_issuance:"تاريخ الإصدار",
      date_of_issuance_is_required:"الرجاء إدخال تاريخ الإصدار",
      place_of_issuance_of_the_passport:"مكان إصدار جواز السفر",
      place_of_issuance_of_the_passport_is_required:"الرجاء إدخال مكان إصدار جواز السفر",
      number_of_entries:"عدد مرات الدخول",
      stay_days:"عدد أيام البقاء",
      religion:"الديانة",
      religion_is_required:"الرجاء إدخال الديانة",
      upload_logo:"تحميل الشعار",
      supported_files:"الملفات المدعومة",
      compnay_name:"اسم الشركة",
      logo_is_required:"الرجاء تحميل الشعار",
      personal_info_hint:"للحصول على تحديثات أسرع، هل يمكنك مشاركتنا ببريدك الإلكتروني الشخصي",
      days:"أيام",
      about_riyadh:"عن الرياض",
      wlc_riyadh:"مرحباً بكم في الرياض، عاصمة المملكة العربية السعودية.",
      about_riyadh_desc_p1:"الرياض منطلق التأسيس، ومهد التاريخ السعودي، وقلب الشرق الأوسط النابض بالنمو والازدهار، تحمل في طياتها تاريخًا عريقًا تزهو به معالمُها.",
      about_riyadh_desc_p2:"الرياض ثالث أكبر عاصمة عربية من حيث عدد السكان بقرابة 7 ملايين نسمة. وتضم على أرضها مقرات المؤسسات السيادية، والوزارات، والهيئات الحكومية والعسكرية. كما تعد مركزًا ماليًا يضم كلًا من مركز الملك عبدالله المالي، وهيئة السوق المالية، والسوق المالية السعودية (تداول السعودية)، ومقرات المصارف وشركات الاستثمار.",
      VRPT:"أبرز المشاريع",
      actT:"معالم وأنشطة",
      act1:"جسر برج المملكة المعلق",
      act2:"قصر المربع التاريخي",
      act3:"وادي نمار",
      act4:"حافة العالم",
      act5:"المتحف الوطني السعودي",
      act6:"برج الفيصلية",
      act7:"قصر المصمك",
      act8:"بوليفارد رياض سيتي",
      act9:"مكتبة الملك فهد الوطنية",
      act10:"واجهة روشن",
      visa_successfully:"شكراً لإرسال معلومات الفيزا.\n سنتصل بك قريباً",
      already_has_visa:"عذراًُ, لقد قمت مسبقا بتسجيل معلومات الفيزا",
      incorrect_link:"عذرا، هذا الرابط غير صحيح",
      company_name:"اسم الشركة",
      of:"من",
      staff:"موظف",
      media_center:"المركز الأعلامي",
      about_p:"تكتسب السعودية موقعا إستراتيجيا، إذ تعد نقطة لالتقاء القارات الكبرى الثلاث، وهي دولة تنعم بمقومات بيئية، واجتماعية، واقتصادية،  وحضارية لا تجتمع  في  مكان آخر.  \n تقترب مساحتها من مساحة قارة ؛2.15  مليون كيلو متر مربع ، وتطل على شواطئ ممتدة من جهتيها؛ الشرقية والغربية.  \n في وسطها تقع عاصمتها الرياض التي تعد ثالث عاصمة عربية من حيث عدد السكان. وفيها المقرات الحكومية  الرئيسة، وتشكل مركزا ماليا عالميا ضخما بالمراكز والهيئات المالية المختلفة المستقرة فيها.  \n وفي غربها تنير بمكة المكرمة حيث بيت الله العتيق، والمدينة المنورة حيث المسجد النبوي ، وهما يشكلان عمق المملكة في الوجدان الإسلامي .  \n وفي المنطقة الشرقية يظهر وجه البلد المُشع 'أرامكو'؛ إذ تمتلك المملكة العربية السعودية ثاني أكبر احتياطي للنفط وسادس احتياطي للغاز، وهي كذلك أكبر مُصدر للنفط الخام في العالم. \n وتمتلك المملكة العربية السعودية إرثا ثقافيا وحضاريا متنوعا يعود إلى آلاف السنين في مساحتها الشاسعة، على رأسها 'العلا'، وهي عضو مؤسس لمنظمة الأمم المتحدة للتربية والثقافة والعلوم 'اليونسكو'، وعضو في مجلسها التنفيذي.",
      learn_more_about_ksa:"اكتشف المزيد عن السعودية",
      learn_more:"للمزيد",
      address:"عنوان الإقامة",
      address_is_required:"الرجاء إدخال عنوان الإقامة",
      registerd_before:"لقد تم تسجيلك بالفعل",
      visa_form:"نموذج الفيزا",
      at_least_one_staff:"يجب إضافة موظف واحد على الأقل",
      add_staff:"إضافة موظف",
      duplicated_emails:"البريد الإلكتروني المستخدم مسبقاً",
      duplicated_phones:"أرقم الهواتف المستخدمة مسبقاً",
      example:"مثل:",
      missing_fields:"الرجاء إكمال جميع الحقول الإلزامية قبل التسجيل",
      personal_photo_hint:"الرجاء إرفاق صورة شخصية عالية الجودة بحجم 2x3 إنش مع خلفية صلبة",
      media_name:"اسم الجهة الإعلامية",
      reg_staff_before:"لقد قمت بالتسجيل مسبقاً",
      hd_logo:"من فضلك، قم بتحميل شعار المنظمة بدقة عالية.",
      invited_by_hint:'من فضلك، ذكر اسم الشركة التي دعتك \n "مثال: صندوق التنمية الوطني" ',
      hosted_by:"Hosetd By",
      media_kit:"Media Kit",
      download:"Download",
      see_more:"See More",
      announcement:"Announcement",
      videos:"Videos",
      images:"Images",
      official_gallery:"Official Gallery",
      view_more:"View More",
      social_media_guidance:"Social Media Guidance",
      share:'share',
      live_streaming:"live streaming",
      arabic:"Arabic",
      english:"English",
      branding:"branding",
      valid_link:"الرجائ التأكد من الرابط",
      session_expire:"انتهاء الجلسة\n يُرجى تحديث معلوماتك.",
      side_event_question:"هل لديك إهتمام للمشاركة بهذه الفعاليات؟",
      available_dates:"التواريخ المتوفرة",
      available_times:"الأوقات المتوفرة",
      select_time:"لم يتم إختيار وقت، \n  الرجاء إختيار وقت",
      choose_room:"اختر غرفة",
      choose_time:"اختر وقت",
      date:"التاريخ",
      from:"من",
      to:"إلى",
      social_media:"تابعنا على",
      Conference_Location:"موقع المؤتمر",
      already_have_side_event:"عذراً، لقد قمت بالتسجيل في الفعالية مسبقاً",
      numnber_of_attendes:"عدد الحضور",
      name_of_attende:"اسم الحاضر",
      max_attendees:"الحد الأقصى لعدد الحضور هو 10",
      scan_badge_1:"يرجى مسح رمز الاستجابة السريعة لحجز غرفة.",
      scan_badge_2:"يرجى التجهيز قبل الاجتماع، على الأقل 10 إلى 5 دقائق مسبقاً، أو قد تفقد غرفة الاجتماع الخاصة بك.",
      room_success_pop:"يرجى التجهيز قبل الاجتماع، على الأقل 10 إلى 5 دقائق مسبقاً، أو قد تفقد غرفة الاجتماع الخاصة بك.تنبيه \n ",
      taken_time:"لقد تم حجز هذا الوقت.",
      no_room_available:"عذراً، لا يوجد غرف فارغة",
      please_select_date_time:"الرجاء إختيار التاريخ والوقت",
      some_selected_event:"الرجاء اختيار التواريخ والأوقات لجميع الفعاليات المختارة",
      already_selected_date:"لقد قمت باختيار هذا التاريخ في فعالية اخرى إذا كنت ترغب باختيار هذا التاريخ يجب عليك إلغاؤه في الفعالية الأخرى",
      personal_image_validation:"الحد الأقصى لحجم الصورة هو 6MB .\n يجب أن يكون عرض وطول الصورة أكبر من 100 بيكسل",
      topic:"الموضوع",
 
      

    },
  },
})
export default i18n

