import { createStore } from "vuex";

export default createStore({
  state: {
    loader:0,
    // BaseApiURL:"https://momentum.ndf.gov.sa/api/",
    // BaseImageURL:"https://momentum.ndf.gov.sa/images/",
    // BaseURL:"https://momentum.ndf.gov.sa/",
    BaseApiURL:"https://momentum.manialab.sa/api/",
    BaseURL:"https://momentum.manialab.sa/",
    BaseImageURL:"https://momentum.manialab.sa/images/",
    // BaseProfileImageURL:"https://momentum.manialab.sa/momentum/storage/app/public/profile/",
    // BaseExhibitorImageURL:"https://momentum.manialab.sa/momentum/storage/app/public/companyLogo/",
    // BaseLocalImage: "imgs/web/visitSaudi/",
    visaLink:"/registration/visa",
    token:"",
    needVisa:0,
    backLink: "",
    tquery: "",
    facebookPattern: /^https:\/\/www\.facebook\.com\/.{3,}$/,
    twitterPattern: /^https:\/\/www\.twitter\.com\/.{3,}$/,
    showMsgDiv: false,
    apiSuccess: true,
    success_msg: "",
    allowedImages: ["jpg", "jpeg", "png"],
    allowedImagesPdf: ["jpg", "jpeg", "png", "pdf"],
    main_about: {},
    main_speaker: {},
    main_panel: {},
    main_exhibitors: {},
    main_faq: {},
    main_agenda: {},
    main_mediaCenter: {},
    sessionExpire: 0,

    error_msg: "",
    enGenderList: [
      { id: "male", text: "Male" },
      { id: "female", text: "Female" },
    ],
    arGenderList: [
      { id: "male", text: "ذكر" },
      { id: "female", text: "أنثى" },
    ],
    enReligion: [
      { id: "muslim", text: "Muslim" },
      { id: "christian", text: "Christian" },
      { id: "other", text: "Other" },
    ],
    arReligion: [
      { id: "muslim", text: "مسلم" },
      { id: "ؤ", text: "مسيحي" },
      { id: "other", text: "ديانة أخرى" },
    ],
    no_visa_countries: ["194", "232", "119", "181", "168", "18"],
    arPassportType: [
      {
        id: "Ordinary Passport",
        text: "جواز عادي",
      },
      {
        id: "Diplomatic Passport",
        text: "جواز دبلوماسي",
      },
      {
        id: "Official Passport",
        text: "جواز رسمي",
      },
      {
        id: "Special or Service Passport",
        text: "جواز خاص",
      },
      {
        id: "Emergency Passport",
        text: "جواز طارئ",
      },
      {
        id: "Child Passport",
        text: "جواز طفل",
      },
      {
        id: "Business Passport",
        text: "جواز الأعمال",
      },
    ],
    enPassportType: [
      {
        id: "Ordinary Passport",
        text: "Ordinary Passport",
      },
      {
        id: "Diplomatic Passport",
        text: "Diplomatic Passport",
      },
      {
        id: "Official Passport",
        text: "Official Passport",
      },
      {
        id: "Special or Service Passport",
        text: "Special or Service Passport",
      },
      {
        id: "Emergency Passport",
        text: "Emergency Passport",
      },
      {
        id: "Child Passport",
        text: "Child Passport",
      },
      {
        id: "Business Passport",
        text: "Business Passport",
      },
    ],
    project1: {
      en: {
        title: "Green Riyadh Project",
        para: "The Green Riyadh Project contributes to increasing the per capita share of green space, and raise the total green spaces by planting trees around all city features and facilities as well as in all its provinces. All of the greenings will be watered by recycled water from an irrigation network. The greening initiative will lead to improved air quality and reduced temperatures in the city. As a consequence, this project will encourage Riyadh citizens to follow a healthy lifestyle.",
      },
      ar: {
        title: "مشروع الرياض الخضراء",
        para: "يهدف المشروع إلى رفع نصيب الفرد من المساحة الخضراء، وزيادة نسبتها الإجمالية من خلال نشر وتكثيف التشجير في كافة أنحاء المدينة ومختلف أرجائها، مع تحقيق الاستغلال الأمثل للمياه المعالجة في أعمال الري، بما يساهم في تحسين جودة الهواء وخفض درجات الحرارة، وتشجيع السكان على ممارسة نمط حياة أكثر نشاطاً.",
      },
    },
    project2: {
      en: {
        title: "Riyadh Season",
        para: "Riyadh Season is one of the largest entertainment seasons held annually in Riyadh. It suits all groups and ages with various entertainment events, and combines Saudi culture with other international cultures, gathering the rarest and most famous international events that are hosted for the first time in the Middle East.",
      },
      ar: {
        title: "موسم الرياض",
        para: "يقام في الرياض سنوياً موسم ترفيهي يعتبر من أضخم المواسم وهو موسم الرياض الذي يناسب جميع الفئات والأعمار بمختلف الفعاليات الترفيهية، ويدمج بين الثقافة السعودية والثقافات العالمية الأخرى في أندر وأشهر الفعاليات العالمية التي تستضاف لأول مرة في الشرق الأوسط.",
      },
    },
    project3: {
      en: {
        title: "Sports Boulevard Project",
        para: "The Sports Boulevard Project aims to encourage residents to follow healthy lifestyles. The project extends over a length of 135 kilometers, providing 4.2 million square meters of green spaces and open areas with an area of ​​more than 3.5 square kilometers of safe and wooded paths for pedestrians, bicycles, and horses, in addition to playgrounds for various sports.",
      },
      ar: {
        title: "مشروع المسار الرياضي",
        para: "يهدف مشروع المسار الرياضي إلى تشجيع السكان على اتباع أنماط حياة صحية، ويمتد المشروع بطول 135 كيلو متر موفراً 4.2 مليون متر مربع من المسطحات الخضراء والمناطق المفتوحة بمساحة تزيد على 3.5 كيلو متر مربع من المسارات الآمنة والمشجرة للمشاة والدراجات الهوائية والخيول، بالإضافة إلى ملاعب لمختلف الرياضات.",
      },
    },
    project4: {
      en: {
        title: "King Salman Park Project",
        para: "King Salman Park aims to be one of the largest city parks in the world, with the project area exceeding 16 square kilometer. The Park will contain environmental elements, a royal arts community, various cultural, recreational, sports and residential facilities, and many public services to provide various sports, cultural, artistic and entertainment options.",
      },
      ar: {
        title: "مشروع حديقة الملك سلمان",
        para: "تهدف حديقة الملك سلمان إلى أن تكون من أكبر حدائق المدن في العالم حيث تتجاوز مساحتها 16 كيلو متر مربع. وهي تحتوي على عناصر بيئية، ومجتمع ملكي للفنون، ومرافق ثقافية وترفيهية ورياضية وسكنية متنوعة، إضافة إلى العديد من الخدمات العامة التي تقدم خيارات متنوعة رياضياً وثقافياً وفنياً وترفيهياً.",
      },
    },
    project5: {
      en: {
        title: "Historic Diriyah Development Program",
        para: "The program aims to transform the historic locations of Diriyah into national cultural centers given the pioneering role of the city as the capital of the First Saudi State.",
      },
      ar: {
        title: "برنامج تطوير الدرعية التاريخية",
        para: "يهدف البرنامج إلى تحويل المناطق الأثرية والتراثية في الدرعية إلى مركز ثقافي وحضاري رئيس على المستوى الوطني، نظراً للدور الريادي والحضاري لهذه المنطقة كمنطلق للدعوة ونواة للدولة السعودية.",
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
