
<template class="">
  <div class="pf c  bgb ov ba z10000">
      <div class="ph30 ld_ph10 pv10">
        <tbl_nm>
          <cl class="vm" style="width:24px;">
            <div class="pt10">
            <!-- <router-link :to="{ name: 'home' }"> -->
            <img src="@/assets/imgs/logo.svg" alt="" class="pointer w100" @click="gotoinnerlink('home')" style="max-width: 24px;">
            <!-- </router-link> -->
          </div>
          </cl>
          <cl class="vm colSep"></cl>
          <cl class="vm wa m_h m_wa">
            <div class="mainNavBar ph20 md_ph4  round2x navbarFlex">
                <div v-if="this.$store.state.main_about.show==1" class="fs18 ld_fs16 md_fs13 navBarItem nav_about_us" @click="gotoinnerlink('about_us')"><span>{{ $t('about_us') }}</span></div>
                <div v-if="this.$store.state.main_speaker.show==1" class="fs18 ld_fs16 md_fs13 navBarItem nav_speakers" @click="gotoinnerlink('speakers')"><span>{{ $t('speakers') }}</span></div>
                <div v-if="this.$store.state.main_panel.show==1" class="fs18 ld_fs16 md_fs13 navBarItem nav_panel_discussion" @click="gotoinnerlink('panel_discussion')"><span>{{ $t('panel_discussion') }}</span></div>
                <div v-if="this.$store.state.main_agenda.show==1" class="fs18 ld_fs16 md_fs13 navBarItem nav_agenda" @click="gotoinnerlink('agenda')"><span>{{ $t('conference_agenda') }}</span></div>
                <div v-if="this.$store.state.main_mediaCenter.show==1" class="fs18 ld_fs16 md_fs13 navBarItem nav_media" :class="{'active' : $route.name == 'MediaCenter' }" >
                  <router-link :to="{ name: 'MediaCenter' }"><span>{{ $t('media_center') }} </span>
                  </router-link></div>
                <div v-if="this.$store.state.main_exhibitors.show==1" class="fs18 ld_fs16 md_fs13 navBarItem nav_exhibitors" @click="gotoinnerlink('exhibitors')"><span>{{ $t('exhibitors') }}</span></div>
                <div v-if="this.$store.state.main_faq.show==1" class="fs18 ld_fs16 md_fs13 navBarItem nav_faq" @click="gotoinnerlink('faq')"><span>{{ $t('faq') }}</span></div>
            </div>
          </cl>
          <cl class="vm colSep"></cl>
          <cl class="vm nw w10 m_wa r" v-if="$route.name !== 'VisitSaudi'">
            <router-link :to="{ name: 'VisitSaudi' }" >
              <button class="b0 b navRegBtn ph20 md_ph8 fs18 ld_fs16 md_fs14 lh40">{{ $t('visit_saudi') }}</button>
            </router-link>
            <div class="g_nm vt pt4 cLightGold pointer ml5" @click="changeLocale()">
              <span class="mdi mdi-earth vm fs22 lh22" ></span>
              <span class="fs18 lh22 upper">{{$i18n.locale === 'ar' ? 'EN' : 'AR'}}</span>
            </div>
            <div class="g_nm h m_sib ml10">
              <span class="mdi mdi-menu menu-btn cLightGold fs20 pointer" @click="showMobileMenu()"></span>
            </div>
          </cl>
          <cl class="wm nw m_wa r" style="width:50px" v-else>
            <div class="g_nm vt pt4 cLightGold pointer ml5" @click="changeLocale()">
              <span class="mdi mdi-earth vm fs22 lh22" ></span>
              <span class="fs18 lh22 upper">{{$i18n.locale === 'ar' ? 'EN' : 'AR'}}</span>
            </div>
            <div class="g_nm h m_sib ml10">
              <span class="mdi mdi-menu menu-btn cLightGold fs20 pointer" @click="showMobileMenu()"></span>
            </div>
          </cl>

        </tbl_nm>
      </div>
  </div>
  <div class="pf ov  bgb pt100 ba bgb z9000  fs16 cw l sideBar">
    <div v-if="this.$store.state.main_about.show==1" class="navSideItem nav_about_us ph10 pointer pv4" @click="gotoinnerlink('about_us')">{{ $t('about_us') }}</div>
    <div v-if="this.$store.state.main_speaker.show==1" class="navSideItem nav_speakers ph10 pointer pv4" @click="gotoinnerlink('speakers')">{{ $t('speakers') }}</div>
    <div v-if="this.$store.state.main_panel.show==1" class="navSideItem nav_panel_discussion ph10 pointer pv4" @click="gotoinnerlink('panel_discussion')">{{ $t('panel_discussion') }} </div>
    <div v-if="this.$store.state.main_agenda.show==1" class="navSideItem nav_agenda ph10 pointer pv4" @click="gotoinnerlink('agenda')">{{ $t('conference_agenda') }}</div>
    <div v-if="this.$store.state.main_mediaCenter.show==1" class="navSideItem nav_media ph10 pointer pv4"><router-link :to="{ name: 'MediaCenter' }">{{ $t('media_center') }}</router-link></div>
    <div v-if="this.$store.state.main_exhibitors.show==1" class="navSideItem nav_exhibitors ph10 pointer pv4" @click="gotoinnerlink('exhibitors')">{{ $t('exhibitors') }}</div>
    <div v-if="this.$store.state.main_faq.show==1" class="navSideItem nav_faq ph10 pointer pv4" @click="gotoinnerlink('faq')">{{ $t('faq') }}</div>
  </div>
  <router-view></router-view>
  <footer class="pt100 pv30 bgb">
    <div class="flexi m_ph10 fs0">
      <div class="g_nm p5 vt w33 m_m_w100 l">
        <div class="b cLightGold fs22 lh20">{{ $t('contact_us') }}</div>
        <div class="cw fs18">hello@helloworld.com</div>
      </div>
      <div class="g_nm p5 vt w33 m_m_w100 c">
        <div class="g_nm l">
        <div class="l b  cLightGold fs22 lh20">{{ $t('Conference_Location') }}</div>
        <div class="l cw fs18">Hotel, Hotel Reoad, City</div>
      </div>
      </div>
      <div class="g_nm p5 vt w33 m_m_w100 r">
        <div class="g_nm l">
          <div class="b cLightGold fs22 mb5 lh20">{{ $t('social_media') }}</div>
          <div class="g_nm"><img src="@/assets/imgs/web/x.png" alt=""></div>
          <div class="g_nm ph8"><img src="@/assets/imgs/web/instagram.png" alt=""></div>
        </div>
      </div>
      <!-- <tbl>
        <cl class="w70 vt l">
          <img  src="@/assets/imgs/registration/regLogo.png" alt="" class="w100" style="max-width: 250px;">
        </cl>
        <cl class="colSep pv8"></cl>
        <cl class="30 vt">
          <tbl_nm>
            <cl class="w75 vt l">
              <div class="b cLightGold fs22 lh20">{{ $t('contact_us') }}</div>
              <div class="cw fs18">hello@helloworld.com</div>
              <div class="b mt10 cLightGold fs22 lh20">Conference Location</div>
              <div class="cw fs18">Hotel, Hotel Reoad, City</div>
            </cl>
            <cl class="w25 vt fs0 r">
              <div class="g_nm ph5"><img src="@/assets/imgs/web/x.png" alt=""></div>
              <div class="g_nm ph5"><img src="@/assets/imgs/web/instagram.png" alt=""></div>
            </cl>
          </tbl_nm>
        </cl>
      </tbl> -->
    </div>
  </footer>
  <div class="pv10 " style="background: linear-gradient(90deg, rgba(209,140,101,1) 0%, rgba(198,167,120,1) 50%, rgba(237,142,37,1) 100%);"></div>
  <div v-if="$store.state.loader==1" class="pf ov bgw20 z10000" style="cursor: not-allowed;">
    <div class="cc">
      <span class="loader"></span>
    </div>
  </div>
  <div v-if="$store.state.showMsgDiv==1" class="pf ov bgb40 overlayDiv" style="z-index:99999999999">
      <div class="cc bgb brdrGold sh20 round2x ph20 pv50 w50 m_w75 m_m_w90 msg_div" style="max-width:350px"> 
        <div v-if="$store.state.apiSuccess==true">
            <p class="success mt20 fs20 enter rregular cLightGold" style="color:#C7A879">{{$store.state.success_msg}}</p>
            <div class="mt20 c">
              <button class="sbtn fs20 success" style="background-color:#C7A879;padding: 8px 40px; border-color:#C7A879 ;" @click="closeModal(1)">{{   this.$t('ok') }}</button>
            </div>
          </div>
          <div v-if="$store.state.apiSuccess==false">
              <p class="error error_msg enter cLightGold mt20 fs30 rregular">{{$store.state.error_msg}}</p>
              <div class="mt20 c">
              <button class="sbtn fs20 success" style="background-color:#C7A879;padding: 8px 40px; border-color:#C7A879 ;" @click="closeModal(0)">{{   this.$t('ok') }}</button>
            </div>
          </div>
      </div>
  </div>
  <div v-if="$store.state.sessionExpire==1" class="pf ov bgb40 overlayDiv" style="z-index:99999999999">
    <div class="cc bgb brdrGold sh20 round2x ph20 pv50 w50 m_w75 m_m_w90 msg_div" style="max-width:350px"> 
      <p class="error error_msg cLightGold mt20 fs30 rregular">{{$t('session_expire')}}</p>
      <div class="mt20 c">
        <button class="sbtn fs20 success" style="background-color:#C7A879;padding: 8px 40px; border-color:#C7A879 ;" @click="sessionExpireClose()">{{   this.$t('ok') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        sectionsControl:{

        }
      }
    },
    name: "WebLayout",
    created(){
      // console.log(this.$route.name=='MediaCenter')
      this.axios.get(this.$store.state.BaseApiURL + "cms/sections", {
      }).then((response) => {
        this.sectionsControl=response.data.data;
        this.$store.state.main_about=this.sectionsControl.find(item => item.section =="about-us" );
        this.$store.state.main_speaker=this.sectionsControl.find(item => item.section =="speakers" );
        this.$store.state.main_panel=this.sectionsControl.find(item => item.section =="panel-discussions" );
        this.$store.state.main_agenda=this.sectionsControl.find(item => item.section =="conferance-agenda" );
        this.$store.state.main_exhibitors=this.sectionsControl.find(item => item.section =="exhibitors" );
        this.$store.state.main_mediaCenter=this.sectionsControl.find(item => item.section =="media-section" );
        this.$store.state.main_faq=this.sectionsControl.find(item => item.section =="faq" );
      })
    .catch((e) => {});
      $(document).ready(function () {
        $(document).on("click", function (event) {
          if (!$(event.target).closest(".sideBar").length && !$(event.target).hasClass("menu-btn") && $(".sideBar").hasClass("active")) {
            $('.sideBar').removeClass('active');
          }
        });
      });
      $(document).on('click', function(event) {
        // Check if the click occurred outside both divs
        // if (!$(event.target).closest('#tooltipBtn_1').length && !$(event.target).closest('#tooltip').length) {
        //   $('#tooltip').removeClass('active')
        // }
        document.addEventListener('click', function(event) {
    var tooltips = document.querySelectorAll('.tooltip'); // Get all tooltip elements
    
    // Loop through each tooltip element
    tooltips.forEach(function(tooltip) {
        // Check if the clicked element is not inside the tooltip itself and not the tooltip trigger button
        if (!tooltip.contains(event.target) && !event.target.classList.contains('tooltipBtn')) {
            tooltip.classList.remove('active'); // Add the 'close' class to hide the tooltip
        }
    });
});
      });
    },
    
    methods:{
      changeLocale() {
        if(this.$i18n.locale=='en'){
          this.$i18n.locale='ar'
          $("html").attr("lang", "ar");
          $('body').addClass("rtl");
        }
        else {
          this.$i18n.locale='en'
          $("html").attr("lang", "en");
          $('body').removeClass("rtl");
        }
      },
      showMobileMenu(){
        if($('.sideBar').hasClass('active')){
          $('.sideBar').removeClass('active');
        }
        else{
          $('.sideBar').addClass('active');
        }
      },
      gotoinnerlink(section_id){
        if (this.$route.name == "home") {
          var x = $("#" + section_id).offset().top - 100;
          $("html, body").animate({
            scrollTop: x,
          },50);
        }
        else {
          var xname = "/";
          var idid = section_id.replace(/\s/g, "");
          this.$router.push({ path: xname, query: { sec: idid } });
        }
        $('.sideBar').removeClass('active');
      },
      closeModal(val){
        this.$store.state.loader=0;
        if(val==1){
          this.$router.push({name:"home"});
          this.$store.state.showMsgDiv=0;
        }
        else{
          this.$store.state.showMsgDiv=0;
        }
      },
      sessionExpireClose(){
        this.$store.state.sessionExpire=0;
        this.$store.state.loader=0;
        console.log(this.$store.state.backLink)
        console.log(this.$store.state.tquery)
        this.$router.push({
          path: this.$store.state.backLink,
          query: { t: this.$store.state.tquery,},
        });
      },
     

    },
  }
</script>
<style>
.navbarFlex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
</style>